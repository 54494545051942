@import 'mixins';
@import 'variables';
@import url('https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css');
// @import url('./fonts');

// =============================================================================
// Swiper Styles Import
// =============================================================================

@import '../node_modules/swiper/swiper.scss';
@import '../node_modules/swiper/modules/navigation/navigation.scss';
@import '../node_modules/swiper/modules/pagination/pagination.scss';
// @import 'swiper/components/scrollbar/scrollbar.scss';

@import './hamburger';
@import './forms/FormsGlobal';

@import './layout/grids';

// STYLES

html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

html {
  --scrollbarBG: #21365b;
  --thumbBG: #82a0bb;
  scroll-padding-top: 75px;
}

body {
  font-family: 'Muli Regular';
  background-color: $off-white;
  line-height: 1.5em;
  color: #2d5092;
}

body::-webkit-scrollbar {
  width: 15px;
}
body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

::-moz-selection {
  background: #aa9f8f;
  color: white;
}
::selection {
  background: #aa9f8f;
  color: white;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3em;
  @include respond-below(sm) {
    line-height: 1.2em;
  }
}
::-webkit-file-upload-button {
  padding: 0.5em 2em;
  color: white;
  background: $pink;
  border: none;
  margin-right: 1rem;
  border-radius: 0.8rem;
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
    box-shadow: 2px 4px 4px rgb(0 0 0 / 20%);
  }
}

a {
  color: $blue;
  text-decoration: none;
}

p {
  a {
    color: $pink;
    text-decoration: none;
    transition: color 0.2s;
    &:hover {
      color: darken($pink, 20%);
    }
  }
}

.largertext p {
  font-size: 120%;
  line-height: 160%;
}

.greenback p,
blockquote {
  font-size: 130%;
  line-height: 160%;
  color: white;
  background-color: $light-green;
  padding: 1.5rem;
  border-radius: 0.5rem;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 2rem;
  @include respond-below(sm) {
    padding: 2rem 2rem;
  }
}

.scroll-padding-top {
  scroll-padding-top: 75px;
}

figure {
  padding: 0;
  margin: 2rem 0;
  img {
    max-width: 100%;
    border-radius: 1rem;
  }
}

.header {
  margin: 0 auto;
  padding: 12px 10px;
  font-size: 25px;
  background-size: 20px;
  background-repeat: repeat-x;
  position: absolute;
  width: 100%;
  max-width: 1400px;
  left: 0;
  right: 0;
}

.header a {
  color: rgb(75, 95, 75);
  display: inline-block;
  padding-top: 10px;
  text-decoration: none;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

// .portable-text {

// }

.copyentry {
  max-width: 960px;
  margin: 0 auto;
}

.customcard {
  text-align: center;
}

.text-center {
  text-align: center;
}

// SWIPER

.flatSlider .swiper-button-prev:after,
.flatSlider .swiper-button-next:after {
  margin-top: -55px;
  color: #fff;
}

.swiper-container {
  padding: 0 1.5rem;
}

.threedSlider .swiper-button-prev:after,
.threedSlider .swiper-button-next:after {
  margin-top: unset;
  color: #fff;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 0px;
  left: auto;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 0px;
  right: auto;
}

.threedSlider .swiper-container {
  padding: 0;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0px;
}

.swiper-container-3d {
  padding: 2rem 0;
}
.seperator {
  width: 100%;
  height: 5px;
  background: darken($off-white, 5%);
  margin: 3rem 0 2rem 0;
}
.spacer {
  width: 100%;
  margin: 4rem 0 2rem 0;
}

.navHolder {
  background-size: 20px;
  background-repeat: repeat-x;
  height: $headertopSpacing;
  position: fixed;
  width: 100%;
  z-index: 10;
  transition: background-color 0.2s;

  .more2logo {
    transition: max-width 0.4s;
  }

  .leftLinks {
    transition: margin-top 0.4s;
  }
  .togglebutton {
    transition: margin-top 0.4s;
  }

  &.active {
    background-color: rgba($off-white, 0.95);
    box-shadow: 2px 4px 4px rgba(black, 0.2);

    .more2logo {
      max-width: 80px;
    }
    .leftLinks {
      margin-top: 10px;
    }
    .togglebutton {
      margin-top: 8px;
    }
  }
}

.OverlayStretch {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transition: 0.3s;
}
.OverlayStretch.hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.pagePagination li {
  display: inline-block;
}

.pagePagination button {
  display: inline-block;
}

.pagePagination {
  width: 100%;
  display: inline-block;
  margin: 20px auto;
  text-align: center;
  padding: 0;
  list-style-type: none;
}

.pagePagination button {
  display: inline-block;
  padding: 0 20px;
  vertical-align: middle;
  line-height: 45px;
  height: 45px;
  box-sizing: border-box;
  border: 1px solid #dee2e6 !important;
  cursor: pointer;
  outline: none;
  font-size: 15px;
  margin: 0 0 0 -1px !important;
  font-weight: 300;
  transition: all 250ms ease-in;
}

.paginationContent {
  min-height: 100px;
  margin-top: 50px;
  font-size: 20px;
  color: #20232a;
}

.paginateButton {
  line-height: 42px;
}

.pagePagination button:hover {
  z-index: 2;
  color: white;
  text-decoration: none;
  background-color: $blue;
  border-color: #dee2e6;
}

.paginateButton:disabled {
  pointer-events: none;
}

.pagePagination button.active {
  color: #fff;
  background-color: $light-blue;
  border: 1px solid darken($light-blue, 10%) !important;
}

.marker1 {
  width: 75px;
  height: 90px;
  background-image: url('/images/static/maps/map-icon-riverside.png');
  background-size: 100%;
  position: absolute;
  bottom: 0;
  left: calc(50% - 38px);
}
.marker2 {
  width: 75px;
  height: 90px;
  background-image: url('/images/static/maps/map-icon-more2childcare.png');
  background-size: 100%;
  position: absolute;
  bottom: 0;
  left: calc(50% - 38px);
}

.mapboxgl-popup-content {
  position: relative;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  padding: 0.75rem 2rem !important;
  pointer-events: auto;
  font-weight: bold;
}

.pinkbutton {
  background: $pink;
  &.active {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    transform: scale(1.05);
    -webkit-box-shadow: 2px 4px 4px rgb(0 0 0 / 20%);
    box-shadow: 2px 4px 4px rgb(0 0 0 / 20%);
    background: #335ba5;
  }
}
.brightgreenbutton {
  background: $light-green;
  &.active {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    transform: scale(1.05);
    -webkit-box-shadow: 2px 4px 4px rgb(0 0 0 / 20%);
    box-shadow: 2px 4px 4px rgb(0 0 0 / 20%);
    background: #335ba5;
  }
}

.flexedholder {
  display: flex;
  gap: 2rem;
  flex-direction: row;

  justify-content: center;
  .flexitem {
    border-radius: 1rem;
  }

  img {
    max-width: 100%;
    width: 100%;
    display: block;
  }

  &.row1 {
    .flexitem {
      background-color: $light-blue;
    }
  }

  &.row2 {
    .flexitem {
      background-color: $pink;
      max-width: 460px;
    }
  }

  &.row3 {
    .flexitem {
      background-color: $light-green;
    }
  }

  @include respond-below(md) {
    flex-wrap: wrap;
  }

  @include respond-below(md) {
    &.row3 {
      .flexitem {
        width: calc(100% / 3 - 1.4rem);
      }
    }
  }
  @include respond-below(sm) {
    &.row2 {
      .flexitem {
        width: calc(100% / 2 - 1.4rem);
      }
    }
    &.row3 {
      .flexitem {
        width: calc(100% / 2 - 1.4rem);
      }
    }
  }

  @include respond-below(xs) {
    &.row2 {
      .flexitem {
        width: 100%;
      }
    }
    &.row3 {
      .flexitem {
        width: 100%;
      }
    }
  }
}

li.disabled a {
  color: rgba(#fff, 1) !important;

  &:hover {
    color: $blue !important;
  }
}

#hiddenlink ol > li:nth-child(2) {
  pointer-events: none;
  a {
    color: rgba(#fff, 0.5) !important;
  }
}

.homecustomPad {
  h3,
  p {
    padding: 0;
    margin: 0;
  }
}

button {
  color: #034f96;
  &.webchildcare {
    background: $bright-green;
  }
  &.webriverside {
    background: $pink;
  }
}

.confetti-pos {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.cookieholder {
  text-align: center;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #6fc4c1;
  height: 70px;
  & > div {
    max-width: 1440px;
    left: unset !important;
  }

  &:before {
    position: absolute;
    width: 100%;
    top: -15px;
    height: 15px;
    content: '';
    background-image: url(/images/static/blue-repeat-pattern.svg);
    transform: scaleY(-1);
    background-size: auto -100%;
  }
}

.form-warning-blink {
  animation: warningBlink 0.5s infinite linear;
}

@keyframes warningBlink {
  0% {
    color: #fff;
  }

  50% {
    color: #ffccd8;
  }

  100% {
    color: #fff;
  }
}

.loading-dots {
  animation: loading 1s infinite;
  display: inline-block;
}

@keyframes loading {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

#__next {
  display: block;
  min-height: 100%;
  min-width: 100%;
  position: relative;
  overflow: hidden;
}

// @media screen and (max-width: 320px) {
//   #__next {
//     display: flex
//   }
// }
