/* -------------------------------------------------------------------------- */
/*                                    GRIDS                                   */
/* -------------------------------------------------------------------------- */

.halfgrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  grid-template-areas: 'firsthalf secondhalf';

  @include respond-below(md) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'firsthalf'
      'secondhalf';
  }

  @include respond-above(lg) {
    &.alternate:nth-child(odd) {
      grid-template-areas: 'secondhalf firsthalf';
    }
  }
}
.firsthalf {
  grid-area: firsthalf;
  img {
    max-width: 100%;
  }
}
.secondhalf {
  grid-area: secondhalf;
  img {
    max-width: 100%;
  }
}

.testiflexorient {
  @include respond-above(lg) {
    display: flex !important;
    flex-direction: row;
    flex-basis: 1;
  }
  gap: 2rem;
  align-items: flex-start;
  justify-content: flex-start;

  img {
    border-radius: 0.5rem;
    max-width: 100%;
    height: auto;
    width: 400px;
    margin: auto;
  }
}

.awardflexorient {

  display: grid !important;
  grid-template-columns: 1fr;
  text-align: center;

  @include respond-above(lg) {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    gap: 0 2rem;
  }

  img {
    border-radius: 0.5rem;
    max-width: 100%;
    height: auto;
    margin: auto;
  }
}

.jobspadding {
  padding: 1rem;
}

.thirdsgrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  grid-template-areas: 'firstthird secondthird thirdthird';

  @include respond-below(md) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'firstthird'
      'secondthird'
      'thirdthird';
  }

  @include respond-above(lg) {
    &.alternate:nth-child(odd) {
      grid-template-areas: 'thirdthird secondthird firstthird';
    }
  }
}
.firstthird {
  grid-area: firstthird;
  img {
    max-width: 100%;
  }
}
.secondthird {
  grid-area: secondthird;
  img {
    max-width: 100%;
  }
}
.thirdthird {
  grid-area: thirdthird;
  img {
    max-width: 100%;
  }
}

.flexdownload {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;

  & > div {
    // display: grid;
    // place-items: center;
    width: calc(100% /3 - 2rem);

    @include respond-below(lg) {
      width: calc(100% /2 - 2rem);

    }
    @include respond-below(sm) {
      width: 100% ;

    }
  }


}
