@import '../mixins';
@import '../variables';

input:not([type="checkbox"]),
select,
textarea {
  width: 100%;
  padding: 0.85rem 1rem;
  outline: none;
  margin: 0.5rem 0 0;
  border-radius: 0.75rem;
  position: relative;
  border: 2px solid transparent;
  transition: 0.2s;
  &:hover {
    border: 2px solid $blue;
    border-radius: 0.75rem;
  }

  &:focus {
    border: 2px solid rgba($blue, 0.7);
    border-radius: 0.75rem;
  }
}

textarea {
  height: calc(100% - 32px);
  resize: vertical;
}

label {
  font-family: 'Muli Bold';
  position: relative;
  left: 1rem;
  width: calc(100% - 2rem)
}

.SendButton {
  margin-top: 2rem;
  background: $blue;
  max-width: calc(50% - 0.5rem);
  color: white;
  font-family: 'Muli Bold';

  @include respond-below(xs) {
    max-width: 100%;
  }
}

span {
  color: white;
  font-size: 0.9rem;
}

.error-input {
  padding: 0.5rem 1rem 0.5rem 0;
  border-radius: 0.75rem;

  span {
    padding: 0.3rem 1rem 0 1rem;
  }

  input,
  textarea, select {
    border: 1px solid red;
    background-color: #ffefef;
  }
}

form {
  button {
    background: $blue;
    padding: 0.75rem 3rem;
    border: 0;
    outline: 0;
    border-radius: 0.5rem;
    transition: 0.2s;
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
      background: lighten($blue, 5%);
    }
  }
}
