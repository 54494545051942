@import 'mixins';
// Colors
$blue : #2d5092;
$light-blue : #82a0bb;
$pink : #cb8799;
$white : #FFF;
$off-white:  #ececec;
$bright-green: #0FC6C2;
$light-green : #6fc4c1;
$light-purple : #a199c4;
$link-green : #0EC5C1;
$blue-grey : #74A2BE;

// Spacing

$headertopSpacing : 75px;


// Box Shadow

$standard-panel-box-shadow : 0 6px 8px rgba(black, 0.3);
