/*FONT MIXIN*/

$min_width: 320px;
$max_width: 1920px;
$min_font: 4px;
$max_font: 18px;


@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
        & {
            font-size: $min-font-size;

            @media screen and (min-width: $min-vw) {
                font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size;
            }
        }
    }
}



/*html {
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
}*/







@mixin gradient($start-color, $end-color, $orientation) {
    background: $start-color;

    @if $orientation=='vertical' {
        background: -webkit-linear-gradient(top, $start-color, $end-color);
        background: linear-gradient(to bottom, $start-color, $end-color);
    }

    @else if $orientation=='horizontal' {
        background: -webkit-linear-gradient(left, $start-color, $end-color);
        background: linear-gradient(to right, $start-color, $end-color);
    }

    @else {
        background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
        background: radial-gradient(ellipse at center, $start-color, $end-color);
    }
}

/*.gradient {
  @include gradient(#07c, #06f, vertical);
}*/





//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––

// A map of breakpoints.
$breakpoints: (ns: 300px,
    xs: 576px,
    sm: 768px,
    md: 992px,
    lg: 1200px,
    xlg: 1600px);


//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-above(sm) {}
@mixin respond-above($breakpoint) {

    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {

        // Get the breakpoint value.
        $breakpoint-value: map-get($breakpoints, $breakpoint);

        // Write the media query.
        @media (min-width: $breakpoint-value) {
            @content;
        }

        // If the breakpoint doesn't exist in the map.
    }

    @else {

        // Log a warning.
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}


//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(sm) {}
@mixin respond-below($breakpoint) {

    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {

        // Get the breakpoint value.
        $breakpoint-value: map-get($breakpoints, $breakpoint);

        // Write the media query.
        @media (max-width: ($breakpoint-value - 1)) {
            @content;
        }

        // If the breakpoint doesn't exist in the map.
    }

    @else {

        // Log a warning.
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}


//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {

    // If both the lower and upper breakpoints exist in the map.
    @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {

        // Get the lower and upper breakpoints.
        $lower-breakpoint: map-get($breakpoints, $lower);
        $upper-breakpoint: map-get($breakpoints, $upper);

        // Write the media query.
        @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
            @content;
        }

        // If one or both of the breakpoints don't exist.
    }

    @else {

        // If lower breakpoint is invalid.
        @if (map-has-key($breakpoints, $lower)==false) {

            // Log a warning.
            @warn 'Your lower breakpoint was invalid: #{$lower}.';
        }

        // If upper breakpoint is invalid.
        @if (map-has-key($breakpoints, $upper)==false) {

            // Log a warning.
            @warn 'Your upper breakpoint was invalid: #{$upper}.';
        }
    }
}



// MIXINS


$spacer:     1rem;
$spacer-x:   $spacer;
$spacer-y:   $spacer;
$spacers: (
  0: (
    x:   0,
    y:   0
  ),
  h: (
    x:   ($spacer-x * 0.5),
    y:   ($spacer-y * 0.5)
  ),
  1: (
    x:   $spacer-x,
    y:   $spacer-y
  ),
  2: (
    x:   ($spacer-x * 1.5),
    y:   ($spacer-y * 1.5)
  ),
  3: (
    x:   ($spacer-x * 3),
    y:   ($spacer-y * 3)
  ),
  4: (
    x:   ($spacer-x * 4),
    y:   ($spacer-y * 4)
  )
);




@mixin spacing() {
  @each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $lengths in $spacers {
      $length-x:   map-get($lengths, x);
      $length-y:   map-get($lengths, y);

      .#{$abbrev}-#{$size} { #{$prop}:         $length-y $length-x !important; }
      .#{$abbrev}t-#{$size} { #{$prop}-top:    $length-y !important; }
      .#{$abbrev}r-#{$size} { #{$prop}-right:  $length-x !important; }
      .#{$abbrev}b-#{$size} { #{$prop}-bottom: $length-y !important; }
      .#{$abbrev}l-#{$size} { #{$prop}-left:   $length-x !important; }
    }
  }
}
